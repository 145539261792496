import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import socialImage from '~/assets/images/social-image.png'

const Metadata = ({
  title,
  description,
  keywords = '',
  noFollow,
  noIndex,
  lang,
  meta,
  twitterImage
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  let robots = []
  noIndex && robots.push('noindex')
  noFollow && robots.push('nofollow')

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords
        },
        {
          name: `robots`,
          content: robots.join(', ')
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: socialImage
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: twitterImage || socialImage,
        },
      ].concat(meta)}
    />
  )
}

Metadata.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

Metadata.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string
}

export default Metadata
